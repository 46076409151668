<div class="header">
  <button mat-icon-button (click)="viewMap()" color="primary"><mat-icon>map</mat-icon></button>
</div>

<div *ngIf="Game.State===GameState.Intro">
  <p class="title">This Spell Book belongs to</p>
  <p class="signature">The Wicked Witch</p>
  <button mat-raised-button color="primary" class="nav" (click)="start()" aria-label="Start button">Start</button>
</div>

<div *ngIf="Game.State===GameState.InProgress">
  <p>Everlasting Darkness Spell</p>
  <p class="question">{{Question.TextBefore}}<br/>
    <img *ngIf="Question.Selected<0" src="assets/marker.png" class="marker"/>
    <span *ngIf="Question.Selected>=0" class="guess">{{Question.Answers[Question.Selected].Text}}</span><br/>
    {{Question.TextAfter}}
  </p>
  <div class="answers">
    <mat-list>
      <mat-list-item *ngFor="let answer of Question.Answers; let index = index">
        <button mat-raised-button [color]="index===Question.Selected ? 'accent' : ''" (click)="select(index)">{{answer.Text}}</button>
      </mat-list-item>
    </mat-list>
  </div>
  <div class="nav">
    <button mat-fab color="accent" (click)="prevPage()" aria-label="Previous page button" *ngIf="Game.CurrentQuestion>0"><mat-icon>navigate_before</mat-icon></button>
    <button mat-fab color="primary" (click)="nextPage()" aria-label="Next page button" [disabled]="Question.Selected<0"><mat-icon>navigate_next</mat-icon></button>
  </div>
</div>

<div *ngIf="Game.State===GameState.ReadyToCast">
  <p class="instructions">You've collected all the ingredients for your potion and you're ready to cast your spell.<br/>
    If you got everything right you should be able to break the Wicked Witch's curse.<br/>
    But be warned if you got anything wrong your spell could backfire.
  </p>
  <button mat-raised-button color="primary" class="nav" (click)="cast()" aria-label="Cast your spell button">Cast Your Spell</button>
  
</div>

<div *ngIf="Game.State===GameState.Success">
  <p class="instructions">Congratulations!<br/>
    You broke the Wicked Witch's curse and light will come back to the Enchanted Forest.
  </p>
  <img src="assets/fireworks.gif" class="finish" alt="Animated Fireworks" />
</div>

<div *ngIf="Game.State===GameState.Fail">
  <p class="instructions">Your spell backfired!<br/>
    You must have got one of the ingredients wrong.<br/>
    You have been cursed to {{Backfire}}.
  </p>
</div>
