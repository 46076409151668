import { Component, OnInit } from '@angular/core';
import { IQuestion } from './iquestion';
import { IGame } from './igame';
import { GameState } from './game-state.enum';
import { MatDialog } from '@angular/material/dialog';
import { MapComponent } from './map/map.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit {
  Game: IGame;
  Question: IQuestion;
  GameState = GameState;
  Backfire = "";

  constructor(private dialog: MatDialog) {
    
  }

  ngOnInit(): void {
    let stringData = localStorage.getItem("ForestState");
    if (stringData) {
      this.Game = JSON.parse(stringData);
      if (this.Game.State === GameState.InProgress)
        this.Question = this.Game.Questions[this.Game.CurrentQuestion];
    } else {
      this.Game = {
        State: GameState.Intro,
        CurrentQuestion: 0,
        Questions: [
          {
            TextBefore: "One Bean",
            TextAfter: "",
            Selected: -1,
            Answers: [
              {
                Text: "Plant",
                Correct: false
              },
              {
                Text: "Stalk",
                Correct: true
              },
              {
                Text: "Bag",
                Correct: false
              }
            ]
          },
          {
            TextBefore: "Three Boiled",
            TextAfter: "",
            Selected: -1,
            Answers: [
              {
                Text: "Eyeballs",
                Correct: true
              },
              {
                Text: "Frogs Legs",
                Correct: false
              },
              {
                Text: "Eggs",
                Correct: false
              }
            ]
          },
          {
            TextBefore: "",
            TextAfter: "Fairy Dust",
            Selected: -1,
            Answers: [
              {
                Text: "Giant's Tree",
                Correct: false
              },
              {
                Text: "Rapunzel Tree",
                Correct: true
              },
              {
                Text: "Oak Tree",
                Correct: false
              }
            ]
          },
          {
            TextBefore: "",
            TextAfter: "Dragon Egg",
            Selected: -1,
            Answers: [
              {
                Text: "Green",
                Correct: false
              },
              {
                Text: "White",
                Correct: false
              },
              {
                Text: "Blue",
                Correct: true
              }
            ]
          },
          {
            TextBefore: "Element",
            TextAfter: "",
            Selected: -1,
            Answers: [
              {
                Text: "Earth",
                Correct: false
              },
              {
                Text: "Fire",
                Correct: true
              },
              {
                Text: "Water",
                Correct: false
              },
              {
                Text: "Air",
                Correct: false
              }
            ]
          },
          {
            TextBefore: "",
            TextAfter: "Bat Wings",
            Selected: -1,
            Answers: [
              {
                Text: "Four",
                Correct: false
              },
              {
                Text: "Six",
                Correct: false
              },
              {
                Text: "Seven",
                Correct: true
              }
            ]
          },
          {
            TextBefore: "Dragon Sword has",
            TextAfter: "Dragons",
            Selected: -1,
            Answers: [
              {
                Text: "Three",
                Correct: false
              },
              {
                Text: "Five",
                Correct: true
              },
              {
                Text: "Seven",
                Correct: false
              }
            ]
          },
          {
            TextBefore: "",
            TextAfter: "Infinity Stone",
            Selected: -1,
            Answers: [
              {
                Text: "White",
                Correct: false
              },
              {
                Text: "Orange",
                Correct: true
              },
              {
                Text: "Blue",
                Correct: false
              }
            ]
          },
        ]
      }
    }
  }

  start() {
    this.Game.State = GameState.InProgress;
    this.Question = this.Game.Questions[this.Game.CurrentQuestion];
    this.saveState();
  }

  nextPage() {
    this.Game.CurrentQuestion++;
    if (this.Game.CurrentQuestion < this.Game.Questions.length)
      this.Question = this.Game.Questions[this.Game.CurrentQuestion];
    else
      this.readyToCast();
    this.saveState();
  }

  prevPage() {
    this.Game.CurrentQuestion--;
    this.Question = this.Game.Questions[this.Game.CurrentQuestion];
  }

  select(index: number) {
    this.Question.Selected = index;
  }

  readyToCast(){
    this.Game.State = GameState.ReadyToCast;
  }

  cast() {
    this.Game.State = this.Game.Questions.every(q=>q.Answers[q.Selected].Correct) ? GameState.Success : GameState.Fail;

    if (this.Game.State == GameState.Fail) {
      const backfires = ["tidy your room", "clean the living room", "do the washing up", "help cook dinner", "wash your own dirty clothes"];
      this.Backfire = backfires[Math.floor(Math.random() * backfires.length)];
    }

    localStorage.removeItem("ForestState");
  }

  saveState() {
    localStorage.setItem("ForestState", JSON.stringify(this.Game));
  }
  
  viewMap() {
    this.dialog.open(MapComponent, {
      maxWidth: '100vw'
    });
  }
}
